<template>
  <a
    href="https://numbersgame.firstpromoter.com/"
    target="_blank"
    class="inline-flex bg-blue-700 items-center underline justify-center border border-blue-700 text-base space-x-4 p-3 px-6 font-bold uppercase text-white"
    ><span>Become an affiliate</span>
    <svg
      class="w-4 h-auto"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2724 1.38182L22.8724 10.9818C23.0179 11.1273 23.1212 11.2848 23.1823 11.4545C23.2433 11.6242 23.2734 11.8061 23.2724 12C23.2724 12.1939 23.2419 12.3758 23.1808 12.5455C23.1197 12.7152 23.0169 12.8727 22.8724 13.0182L13.2724 22.6182C13.0058 22.8848 12.6722 23.024 12.2717 23.0356C11.8712 23.0473 11.526 22.9081 11.2361 22.6182C10.9452 22.3515 10.7934 22.0179 10.7808 21.6175C10.7682 21.217 10.9078 20.8718 11.1997 20.5818L18.327 13.4545L2.07244 13.4545C1.66032 13.4545 1.31462 13.3149 1.03535 13.0356C0.756077 12.7564 0.616924 12.4112 0.617893 12C0.617893 11.5879 0.757046 11.2422 1.03535 10.9629C1.31365 10.6836 1.65935 10.5445 2.07244 10.5455L18.327 10.5455L11.1997 3.41818C10.933 3.15151 10.7934 2.81212 10.7808 2.4C10.7682 1.98788 10.9078 1.64849 11.1997 1.38182C11.4664 1.09091 11.8058 0.945454 12.2179 0.945454C12.63 0.945454 12.9815 1.09091 13.2724 1.38182Z"
        fill="white"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "AffiliateButton",
};
</script>

<style></style>
