const tikTokPosts = [
  {
    id: "7233043211126623515",
  },
  {
    id: "7233009189520510234",
  },
  {
    id: "7232624520769654042",
  },
  {
    id: "7233420344026008858",
  },
  {
    id: "7233409636026633499",
  },
  {
    id: "7233765489829301531",
  },
    {
    id: "7232279135027039514",
  },
];

export default tikTokPosts;
