const reviews = [
  {
    who: "David O'Leary",
    quote:
      "<p>“Numbers Game has already returned my outlay and I wondered whether more leagues will be include, increased data, etc, going forward?</p><p>Thank you for your data and insight that I never had to hand before, keep up the good work.”</p>",
    date: "27 March 2023",
  },
  {
    who: "Matthias Diaz",
    quote:
      "<p>“I have had lot of success betting on btts through numbers game. Thanks guys”</p>",
    date: "12 January 2023",
  },
  {
    who: "Louis Rowley",
    quote:
      "<p>“I won one bet in my first week using Numbers Game that paid for a years subscription. Looking forward to using it some more and hopefully continuing with that juicy ROI.”</p>",
    date: "14 December 2022",
  },
  {
    who: "Mo Iqbal",
    quote:
      "<p>“I've been using this website for a few months now and it's been a game changer for me. I'd recommend it for anyone looking to have more fun and improve their betting.”</p>",
    date: "09 December 2022",
  },
  {
    who: "Aaron Poole",
    quote:
      "<p>“Building Acca's is more fun using numbers game, I still don't win every one but I deffo reckon my win rate has improved. Keep up the good work lads.”</p>",
    date: "02 October 2022",
  },
  {
    who: "Oliver Tombs",
    quote:
      "<p>“Numbers Game is now my go to for match days, not always bothered about the predictions but love looking at the data.”</p>",
    date: "20 February 2023",
  },
];

export default reviews;
