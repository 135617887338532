<template>
  <div>
    <Header />

    <div class="bg-pattern bg-center relative">
      <main class="p-4 pt-20  max-w-6xl mx-auto">
        <h1
          class="font-bold uppercase text-black text-center text-3xl mb-4 mt-4"
        >
          GET PAID EVERY TIME YOU REFER A MATE
        </h1>
        <div class="space-y-4 relative z-10">
          <p class="text-center">
            Refer someone to a Numbers Game subscription and you’ll receive up
            to
            <strong class="uppercase text-blue-700 underline"
              >30% commission!</strong
            >
            With so many football fans to go at, Numbers Game is the perfect
            tool to for those looking to make better bets and earn money by
            giving them data at their fingertips.
          </p>
          <p class="text-center py-10">
            <AffiliateButton />
          </p>
          <VueSlickCarousel
            v-bind="slideSettingsTikTok"
            class="-mx-3 md:mx-auto mt-10 overflow-x-hidden"
          >
            <div
              v-for="(item, key) in tikTokPosts"
              :key="key"
              class="px-1 md:px-2"
            >
              <blockquote
                class="tiktok-embed"
                :cite="`https://www.tiktok.com/@kvzmo.ae/video/${item.id}`"
                :data-video-id="item.id"
              >
                <section></section>
              </blockquote>
            </div>
          </VueSlickCarousel>
        </div>
        <div
          class="h-1/2 bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-white absolute"
        ></div>
      </main>
    </div>

    <div class="relative border-b-2 border-gray-600 pt-10">
      <div
        class="h-[200px] top-[20%] left-0 right-0 bg-gradient-to-b from-transparent to-brandGrey/20 absolute"
      ></div>
      <div
        class="bottom-0 top-[calc(20%+200px)] left-0 right-0 bg-brandGrey/20 absolute"
      ></div>
      <div class="z-10 relative">
        <div class="max-w-6xl mx-auto px-4">
          <div>
            <h2
              class="font-bold text-black uppercase text-2xl lg:text-3xl mb-4 mt-4 lg:pb-4"
            >
              Why Numbers Game?
            </h2>
            <p>
              Football is a crazy world, and betting on it is no different. It
              would be shit if you could accurately predict the outcome of every
              match (betting would be alright though). As much as that remains
              our goal, we would prefer it to stay out of reach. What we are
              hoping to achieve is to help the average punter make better
              informed decisions based on real data (and hopefully doing better
              at the bookies in the process).
            </p>
            <div class="py-4 mx-auto max-w-2xl pt-14">
              <div
                class="relative h-0 overflow-hidden max-w-full w-full"
                style="padding-bottom: 56.25%"
              >
                <iframe
                  src="https://www.youtube.com/embed/zSVFHuZhXcw?autoplay=1"
                  frameborder="0"
                  allowfullscreen
                  class="absolute top-0 left-0 w-full h-full"
                ></iframe>
              </div>
            </div>
            <p class="text-center py-10 pb-20">
              <AffiliateButton />
            </p>
          </div>
        </div>

        <div class="relative Z-10">
          <div class="max-w-6xl mx-auto px-4">
            <div>
              <h2
                class="font-bold uppercase text-black text-2xl lg:text-3xl mb-4 mt-4 lg:pb-4"
              >
                How You can start making 💰💰💰
              </h2>

              <div class="space-y-6">
                <div class="flex p-4 md:p-6 bg-white border border-gray-400 relative">
                  <span
                    class="font-bold bg-blue-700 h-6 w-6 md:w-10 md:h-10 flex items-center justify-center md:text-xl text-white"
                    >1</span
                  >
                  <div class="flex-1 ml-4">
                    <p class="uppercase font-bold md:text-xl">
                      Create your affiliate account with Numbers Game
                    </p>

                    <ul class="list-disc pl-4 space-y-2 mt-4 md:text-base text-sm">
                      <li>
                        Creating your affiliate account takes less than 5mins.
                        We’ve made the process easy, fast and straightforward.
                      </li>
                      <li>
                        Once you fill in your basic details, your account will
                        be created and you will be able to be assigned with an
                        affiliate link.
                      </li>
                      <li>
                        Your affiliate dashboard has everything you need from
                        guides on how to get high conversions to beautifully
                        designed promotional materials.
                      </li>
                      <li>
                        Your account will be created within seconds and ready
                        for you to start promoting.
                      </li>
                    </ul>
                  </div>
                  <div
                    class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                  ></div>
                </div>

                <div class="flex p-4 md:p-6 bg-white border border-gray-400 relative">
                  <span
                    class="font-bold bg-blue-700 h-6 w-6 md:w-10 md:h-10 flex items-center justify-center md:text-xl text-white"
                    >2</span
                  >
                  <div class="flex-1 ml-4">
                    <p class="uppercase font-bold md:text-xl">
                      Get access to your affiliate link
                    </p>

                    <ul class="list-disc pl-4 space-y-2 mt-4 md:text-base text-sm ">
                      <li>
                        Once you’ve successfully created your account, you will
                        be assigned a unique affiliate link. This unique link is
                        what you need to share when you promote Numbers Game.
                      </li>
                      <li>
                        Everytime someone clicks on your unique link, you will
                        start seeing the report in your dashboard. If they
                        convert to the subscription, you will be able to see the
                        conversion in your dashboard.
                      </li>
                    </ul>
                  </div>
                  <div
                    class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                  ></div>
                </div>

                <div class="flex p-4 md:p-6 bg-white border border-gray-400 relative">
                  <span
                    class="font-bold bg-blue-700 h-6 w-6 md:w-10 md:h-10 flex items-center justify-center md:text-xl text-white"
                    >3</span
                  >
                  <div class="flex-1 ml-4">
                    <p class="uppercase font-bold md:text-xl">
                      Start making 🤑🤑🤑
                    </p>

                    <ul class="list-disc pl-4 space-y-2 mt-4 md:text-base text-sm ">
                      <li>
                        Get paid a 30% comission every time someone signs up for
                        our annual subscription, or 20% for every month that
                        they're subscribed to our monthly package!
                      </li>
                    </ul>
                  </div>
                  <div
                    class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                  ></div>
                </div>
              </div>

              <div class="mt-10">
                <div class="mx-auto max-w-xl space-y-4">
                  <h2
                    class="font-bold text-black text-2xl lg:text-3xl mb-4 mt-4"
                  >
                    Perks of Promoting Numbers Game
                  </h2>
                  <div
                    class="flex p-2 bg-white border border-gray-400 relative"
                  >
                    <svg
                    class="w-5 mr-2 h-auto flex-shrink-0"
                      width="40"
                      height="31"
                      viewBox="0 0 40 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M39.75 3.24061L12.75 30.2406L0.375 17.8656L3.5475 14.6931L12.75 23.8731L36.5775 0.0681152L39.75 3.24061Z"
                        fill="#65EE83"
                      />
                    </svg>

                    <p class="uppercase text-sm md:text-base">
                      Backed by world leading machine learning algorithms 👨‍💻
                    </p>
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                  </div>
                  <div
                    class="flex p-2 bg-white border border-gray-400 relative"
                  >
                    <svg
                    class="w-5 mr-2 h-auto flex-shrink-0"
                      width="40"
                      height="31"
                      viewBox="0 0 40 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M39.75 3.24061L12.75 30.2406L0.375 17.8656L3.5475 14.6931L12.75 23.8731L36.5775 0.0681152L39.75 3.24061Z"
                        fill="#65EE83"
                      />
                    </svg>

                    <p class="uppercase text-sm md:text-base">
                      No intrusive advertising, just the info you need! 📖
                    </p>
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                  </div>
                  <div
                    class="flex p-2 bg-white border border-gray-400 relative"
                  >
                    <svg
                    class="w-5 mr-2 h-auto flex-shrink-0"
                      width="40"
                      height="31"
                      viewBox="0 0 40 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M39.75 3.24061L12.75 30.2406L0.375 17.8656L3.5475 14.6931L12.75 23.8731L36.5775 0.0681152L39.75 3.24061Z"
                        fill="#65EE83"
                      />
                    </svg>

                    <p class="uppercase text-sm md:text-base">
                      Polished user experience 💅🏻
                    </p>
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                  </div>
                  <div
                    class="flex p-2 bg-white border border-gray-400 relative"
                  >
                    <svg
                    class="w-5 mr-2 h-auto flex-shrink-0"
                      width="40"
                      height="31"
                      viewBox="0 0 40 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M39.75 3.24061L12.75 30.2406L0.375 17.8656L3.5475 14.6931L12.75 23.8731L36.5775 0.0681152L39.75 3.24061Z"
                        fill="#65EE83"
                      />
                    </svg>

                    <p class="uppercase text-sm md:text-base">
                      2500+ games accurately predicted so far this season, and
                      counting 👀
                    </p>
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                  </div>
                </div>
              </div>

              <p
                class="font-bold text-center text-lg max-w-4xl mx-auto pt-10 pb-10"
              >
                With 26% of the worldwide population placing bets and billions
                of football fanatics around the world, what’s stopping you from
                getting started today?
              </p>
            </div>
          </div>
          <div
            class="h-3/4 bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-brandGrey/20 absolute"
          ></div>
        </div>

        <div class="bg-brandGrey/20">
          <VueSlickCarousel
            v-bind="slideSettings"
            class="pt-10 pb-10 overflow-hidden"
          >
            <InstagramPost postID="CoIQV0xoAPa" postImage="CoIQV0xoAPa.jpg" />
            <InstagramPost postID="CjlBahUI1OS" postImage="CjlBahUI1OS.jpeg" />
            <InstagramPost postID="CrDRdP3o244" postImage="CrDRdP3o244.jpg" />
            <InstagramPost postID="CdTheeWI_mB" postImage="CdTheeWI_mB.jpeg" />
            <InstagramPost postID="CcKr5hFInwo" postImage="CcKr5hFInwo.jpg" />
            <InstagramPost postID="CbiATB-I5SD" postImage="CbiATB-I5SD.jpeg" />
            <InstagramPost postID="CbNmrMdInkp" postImage="CbNmrMdInkp.jpeg" />
            <InstagramPost postID="CaQf731oTPL" postImage="CaQf731oTPL.jpeg" />
          </VueSlickCarousel>

          <p class="text-center py-10">
            <AffiliateButton />
          </p>

          <div class="pb-10">
            <div class="max-w-4xl mx-auto px-4">
              <h4 class="font-bold text-3xl">
                User<br />
                Reviews
              </h4>
            </div>

              <!-- <div
              class="px-4 space-y-4 xl:space-y-0 xl:flex items-center justify-center xl:space-x-4 mt-10"
            > -->
            <VueSlickCarousel
              v-bind="slideSettings"
              class="mt-10 px-1 md:px-0 overflow-hidden"
            >
              <div
                :href="item.link"
                v-for="(item, key) in reviews"
                :key="key"
                class="flex-1 px-1 md:px-2"
                target="_blank"
              >
                <div class="bg-white shadow-lg p-4">
                  <div class="flex items-center justify-center">
                    <img
                      :src="`/reviews/${key + 1}.png`"
                      class="rounded-full h-6 w-6 md:w-10 md:h-10 overflow-hidden"
                md:    />
                    <div class="flex-1 ml-2">
                      <h4 class="font-bold">{{ item.who }}</h4>
                      <p class="text-brandGrey text-xxs">{{ item.date }}</p>
                    </div>
                  </div>

                  <div class="text-xs space-y-4 mt-4" v-html="item.quote"></div>
                </div>
              </div>
            </VueSlickCarousel>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Vue from "vue";
import Header from "./components/Header";
import Footer from "./components/Footer";
import tikTokPosts from "./data/TikToks";
import reviews from "./data/Reviews";

import AffiliateButton from "./components/AffiliateButton";
import InstagramPost from "./components/InstagramPost";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Affiliate",
  components: {
    Header,
    Footer,
    AffiliateButton,
    InstagramPost,
    VueSlickCarousel,
  },
  data() {
    return {
      tikTokPosts: [],
      reviews: [],
      slideSettings: {
        autoplay: true,
        slidesToShow: 5,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slideSettingsTikTok: {
        autoplay: true,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },
  created() {
    this.tikTokPosts = tikTokPosts;
    this.reviews = reviews;
  },
  mounted() {
    Vue.loadScript("https://www.tiktok.com/embed.js")
      .then(() => {
        // Script is loaded, do something
      })
      .catch(() => {
        // Failed to fetch script
      });
  },
};
</script>

<style></style>
